import Cookies from 'js-cookie'

// Scroll lock
const popupModal = () => {
  const modal = document.getElementById('popup-modal')
  const close = document.getElementById('hide-modal')

  if(!Cookies.get('age-check', 'closed')) {
    modal.classList.remove('hidden')
    modal.classList.add('flex')
  }

  close.addEventListener('click', (event) => {
    modal.classList.add('hidden')
    Cookies.set('age-check', 'closed', '{ expires: 56 }')
  })
};

export default popupModal;
