// Import Alert
import Alert from './modules/alert';

// Menu
import Menu from './modules/menu';

// popupModal
import popupModal from './modules/popup-modal';

// Import Font loader
import WebFont from 'webfontloader';

import Flickity from 'flickity';

export default {
  init () {
    // JavaScript to be fired on all pages

    // Alert
    if (document.getElementById('tofino-notification')) {
      Alert();
    }

    // Menu
    if (document.getElementById('main-menu')) {
      Menu();
    }
    
    // Menu
    if (document.getElementById('popup-modal')) {
      popupModal();
    }

    console.log('rendering')
    var elem = document.querySelector('.main-carousel');
    var flkty = new Flickity(elem, {
      // options
      cellAlign: 'left',
      contain: true,
      autoPlay: 3500,
      wrapAround: true,
      pauseAutoPlayOnHover: false,
      prevNextButtons: false,
      pageDots: false,
      resize: true
    });

    window.addEventListener("resize", (event) => {
      flkty.resize();
    })
  },
  finalize () {
    // JavaScript to be fired after init


  },
  loaded () {
    // Javascript to be fired once fully loaded
  },
};
